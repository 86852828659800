:root {
  // Edit here to modify styles.

  // The theme color of the application.
  --color-theme: rgb(250, 175, 15);
  --color-theme: rgb(55, 140, 255);
  --color-text-on-theme: rgb(255, 255, 255);

  // The notification color of the application.
  --color-positive-bright: rgb(150, 175, 100); 
  --color-warning-bright : rgb(220, 150, 15); 
  --color-negative-bright: rgb(225, 60, 60); 
  
  --color-positive-dark  : rgb(150, 175, 100); 
  --color-warning-dark   : rgb(220, 150, 15); 
  --color-negative-dark  : rgb(255, 80, 80); 
  
  // Color swatches for grayscale:
  --color-bright-4: white;
  --color-bright-3: rgb(245, 245, 247);
  --color-bright-2: rgb(234, 234, 239);
  --color-bright-1: rgb(205, 205, 220);
  --color-gray    : rgb(117, 117, 128);
  --color-dark-1  : rgb(62, 62, 75);
  --color-dark-2  : rgb(40, 40, 52);
  --color-dark-3  : rgb(22, 22, 34);
  --color-dark-4  : black;
  
  // Color swatches for captions:
  --color-caption-bright: var(--color-gray);
  --color-caption-dark  : var(--color-gray);

  // Color swatches for checkboxes:
  --color-checkbox-bright         : var(--color-gray);
  --color-checkbox-active-bright  : var(--color-theme);
  --color-checkbox-disabled-bright: var(--color-bright-1);
  --color-checkbox-dark           : var(--color-gray);
  --color-checkbox-active-dark    : var(--color-theme);
  --color-checkbox-disabled-dark  : var(--color-dark-1);

  // Color swatches for links:
  --color-link-bright: var(--color-theme);
  --color-link-dark  : var(--color-theme);

  // Color swatches for progress:
  --color-progress-bright         : var(--color-theme);
  --color-progress-dark           : var(--color-theme);
  --color-progress-bright-disabled: var(--color-bright-1);
  --color-progress-dark-disabled  : var(--color-dark-1);

  // color swatches for shadow:
  --color-shadow-bright: rgba(0, 0, 0, .08);
  --color-shadow-dark  : rgba(0, 0, 0, .08);
  
  // Image URL for company logo:
  --image-company-bright: url('https://accounts.stella-it.com/_common/img/logo_black.svg');
  --image-company-dark  : url('https://accounts.stella-it.com/_common/img/logo_white.svg');
  
  // Spacing customization:
  --space-xl  : 3rem;
  --space-l   : 2rem;
  --space-r   : 1.5rem;
  --space-s   : 1rem;
  --space-xs  : .75rem;
  --space-xxs : .5rem;
  --space-xxxs: .25rem;

  // Border radius customization
  --border-radius: .25rem;

  // Transition customization:
  --time-long : .2s;
  --time-short: .1s;

  // Opacity customization:
  --opacity-hover-focus: .75;
}

// Social Buttons customization:
@mixin social-button-github {
  --color-theme: var(--color-text);
}
@mixin social-button-naver {
  --color-theme: rgb(30, 200, 0);
}
@mixin social-button-kakao {
  --color-theme: rgb(254, 229, 0);
}
@mixin social-button-google {
  --color-theme: var(--color-background-contents);
  border: 4px solid var(--color-background-body);
}


// The following styles are designed for managing 
// behavior of the internal scss styling. 
//
// Please use :root for style edits instead.

@mixin theme-light {
  --image-company: var(--image-company-bright);
  
  --color-text                         : var(--color-dark-4);
  --color-link                         : var(--color-link-bright);
  --color-caption                      : var(--color-caption-bright);
  --color-positive                     : var(--color-positive-bright);
  --color-warning                      : var(--color-warning-bright);
  --color-negative                     : var(--color-negative-bright);
  --color-disabled-text                : var(--color-gray);
  --color-disabled-background          : var(--color-bright-2);
  --color-background-body              : var(--color-bright-3);
  --color-background-contents          : var(--color-bright-4);
  --color-background-progress          : var(--color-bright-2);
  --color-background-progress-disabled : var(--color-progress-bright-disabled);
  --color-background-textfield-disabled: var(--color-bright-2);
  --color-progress                     : var(--color-progress-bright);
  --color-border-textfield             : var(--color-bright-1);
  --color-border-checkbox              : var(--color-checkbox-bright);
  --color-border-checkbox-active       : var(--color-checkbox-active-bright);
  --color-border-checkbox-disabled     : var(--color-checkbox-disabled-bright);
  --shadow                             : 0 4px 16px var(--color-shadow-bright);
}

@mixin theme-dark {
  --image-company: var(--image-company-dark);
  
  --color-text                         : var(--color-bright-4);
  --color-link                         : var(--color-link-dark);
  --color-caption                      : var(--color-caption-dark);
  --color-positive                     : var(--color-positive-dark);
  --color-warning                      : var(--color-warning-dark);
  --color-negative                     : var(--color-negative-dark);
  --color-disabled-text                : var(--color-gray);
  --color-disabled-background          : var(--color-dark-2);
  --color-background-body              : var(--color-dark-3);
  --color-background-contents          : var(--color-dark-4);
  --color-background-progress          : var(--color-dark-2);
  --color-background-progress-disabled : var(--color-progress-dark-disabled);
  --color-background-textfield-disabled: var(--color-dark-2);
  --color-progress                     : var(--color-progress-dark);
  --color-border-textfield             : var(--color-dark-1);
  --color-border-checkbox              : var(--color-checkbox-dark);
  --color-border-checkbox-active       : var(--color-checkbox-active-dark);
  --color-border-checkbox-disabled     : var(--color-checkbox-disabled-dark);
  --shadow                             : 0 4px 16px var(--color-shadow-dark);
}

body {
  &.theme-light {
    @include theme-light;
  }
  &.theme-dark {
    @include theme-dark;
  }
}
