ul.scope_list {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;

  width: max-content;

  & > li.scope_list_item:not(:first-child) {
    margin-top: var(--space-xxs);
  }
}