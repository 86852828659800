progress {
  padding: 0;
  margin: 0;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  display: block;
  border: none;

  height: 7px;
  border: none;

  // Internet Explorer "support"
  color: var(--color-progress);

  &.grow {
    display: block;
    width: 100%;
  }

  &::-webkit-progress-bar {
    background-color: var(--color-background-progress);
    margin: 0; padding: 0;
    border: none;
    vertical-align: -0.2em;
  }

  &::-webkit-progress-value {
    background-color: var(--color-progress);
    margin: 0; padding: 0;
    border: none;
    vertical-align: -0.2em;
    transition: width .2s;
  }

  &::-moz-progress-bar {
    background-color: var(--color-progress);
    transition: width .2s;
  }

  &.disabled {
    &::-webkit-progress-bar {
      background-color: var(--color-disabled-background);
    }

    &::-webkit-progress-value {
      background-color: var(--color-background-progress-disabled);
    }

    &::-moz-progress-bar {
      background-color: var(--color-background-progress-disabled);
    }
  }
}

