#content.password {
  & > div.textfield_wrapper, & > div.textlink_wrapper {
    margin-top: var(--space-r);
  }

  & > div.textlink_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > a.textlink:not(:first-child) {
      margin-top: var(--space-xs);
    }
  }
}

input.hidden {
  display: none;
}
