div.btn_row_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}
