@import "../../../style/mixin";

html,
body {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100%;

  overflow-y: auto;

  @include media-mobile-only {
    padding: var(--space-s) 0;
    align-items: unset;

    height: unset;
  }
}

#wrapper {
  margin: var(--space-r) auto 0;
  max-width: 30rem;
  width: calc(100vw - 2 * var(--space-r));

  @include media-mobile-only {
    max-width: none;
    width: calc(100vw - 2 * var(--space-s));
  }

  #content_wrapper {
    width: 100%;
    background: var(--color-background-contents);
    border-radius: var(--border-radius);
    overflow: hidden;

    #content {
      width: 100%;
      padding: var(--space-l);

      & > *:first-child {
        margin-top: 0;
      }

      & > *:last-child {
        margin-bottom: 0;
      }

      @include media-mobile-only {
        padding: var(--space-r);
      }
    }

    &.progress > #content {
      padding-top: calc(var(--space-r) + 1px);

      @include media-mobile-only {
        padding: var(--space-s);
      }
    }
  }

  & > div.btn_row_wrapper {
    margin-top: var(--space-r);
  }
}
