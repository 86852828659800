li.select_account_item {
  list-style-type: none;

  & > a, & > button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--space-s);
    
    border: 0;
    background: transparent;
    color: var(--color-text);
    text-align: left;
    text-decoration: none;

    border-radius: var(--border-radius);
    transition-duration: var(--time-long);

    cursor: pointer;

    &:hover {
      background: var(--color-background-body);
    }
  
    & > img {
      height: 4rem;
      border-radius: var(--border-radius);
      margin-right: var(--space-s);
    }
  
    & > span {
      display: block;
      height: 100%;
  
      & > span {
        display: block;
        margin: 0;

        &:first-child {
          font-size: 1.25rem;
          font-weight: bold;
        }

        &:last-child {
          margin-top: .125rem;
          font-size: .875rem;
        }
      }
    }
  }
}
