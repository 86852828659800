@import '../../../style/mixin';

button.btn, a.btn, span.btn {
  display: inline-block;
  padding: 0 calc(2rem - 1px);

  color: var(--color-theme);
  font-size: 1rem;
  line-height: calc(3.5rem - 2px);
  text-decoration: none;
  border: 1px solid var(--color-theme);
  border-radius: var(--border-radius);
  
  transition-duration: var(--time-short);

  @include media-mobile-only {
    padding: 0 calc(1.5rem - 1px);
    line-height: calc(3rem - 2px);
  }

  &.primary {
    color: var(--color-text-on-theme);
    background-color: var(--color-theme);

    &:disabled, &.disabled {
      color: var(--color-disabled-text);
      border-color: var(--color-disabled-background);
      background-color: var(--color-disabled-background);
    }

    &:not(:disabled):hover, &:not(:disabled):focus,
    &:not(.disabled):hover, &:not(.disabled):focus {
      color: var(--color-theme);
      background-color: transparent;
      box-shadow: var(--shadow);
    }
  }

  &.secondary {
    background-color: transparent;

    &:disabled, &.disabled {
      color: var(--color-disabled-text);
      border-color: var(--color-disabled-background);
      background-color: var(--color-disabled-background);
    }

    &:not(:disabled):hover, &:not(:disabled):focus,
    &:not(.disabled):hover, &:not(.disabled):focus {
      color: var(--color-text-on-theme);
      background-color: var(--color-theme);
      box-shadow: var(--shadow);
    }
  }

  &.tertiary {
    border-color: transparent;

    &:disabled, &.disabled {
      color: var(--color-disabled-text);
    }

    &:not(:disabled):hover, &:not(:disabled):focus,
    &:not(.disabled):hover, &:not(.disabled):focus {
      color: var(--color-text);
    }
  }

  &.grow {
    width: 100%;
    text-align: center;
  }

  &:disabled, &.disabled {
    pointer-events: none;
  }

  &:not(:disabled), &:not(.disabled) {
    cursor: pointer;

    &:active {
      transform: translateY(2px);
    }
  }

  &.square {
    padding: 0;
    width: 3.5rem;
  
    @include media-mobile-only {
      width: 3rem;
    }
  }
}
