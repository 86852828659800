@import 'mixin';

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  font-family: 'Noto Sans CJK KR', sans-serif;
  letter-spacing: -0.02rem;
}

html {
  font-size: 16px;

  @include media-mobile-only {
    font-size: 14px;
  }

  @include media-desktop-only {
    font-size: 18px;
  }
}

body {
  color: var(--color-text);
  background-color: var(--color-background-body);

  transition-duration: var(--time-long);
}

input, button, textarea {
  font-family: inherit;
  font-size: inherit;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  margin-top: .5em;
  margin-bottom: var(--space-s);
}

h1 {
  font-size: 1.5rem;
}