div.textfield_wrapper {
  &.half {
    width: calc(50% - .75rem);
  }

  &.positive {
    input {
      border-color: var(--color-positive);
      color: var(--color-positive);
      outline-color: var(--color-positive);

      &:hover, &:active, &:focus {
        color: var(--color-positive);
      }
    }

    p.caption {
      color: var(--color-positive);
    }
  }

  &.warning {
    input {
      border-color: var(--color-warning);
      color: var(--color-warning);
      outline-color: var(--color-warning);

      &:hover, &:active, &:focus {
        color: var(--color-warning);
      }
    }

    p.caption {
      color: var(--color-warning);
    }
  }

  &.negative {
    input {
      border-color: var(--color-negative);
      color: var(--color-negative);
      outline-color: var(--color-negative);

      &:hover, &:active, &:focus {
        color: var(--color-negative);
      }
    }

    p.caption {
      color: var(--color-negative);
    }
  }
  
  p.caption:not(:empty) {
    margin-top: .75rem;
  }
}
